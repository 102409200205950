import React from 'react';
import '../css/Portfolio.css';
import image1 from '../assets/images/WhatsApp Image 2024-08-11 at 11.33.24 AM.jpeg';
import { FaArrowRight } from 'react-icons/fa';  // Import the right arrow icon

function Portfolio() {
  return (
    <section id="portfolio" className="portfolio">
      <h2>Our Portfolio</h2>
      <p>Explore our latest projects and see how we've transformed visions into reality.</p>
      <div className="portfolio-gallery">
        <div className="portfolio-item">
          <img src={image1} alt="Clinic Khojo Main App" />
          <h3>Clinic Khojo</h3>
          <p>The app that allows users to book appointments seamlessly with doctors.</p>
          <button className="visit-website-button" onClick={() => window.location.href='https://clinickhojo.com'}>
            Visit Website <FaArrowRight />
          </button>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
