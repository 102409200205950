import React from 'react';
import '../css/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="contact-info">
        <h3>Contact Information</h3>
        <p><strong>Address:</strong> Begusarai, Bihar, 851101</p>
        <p><strong>Email:</strong> <a href="mailto:techshamyani@gmail.com">techshamyani@gmail.com</a></p>
        <p><strong>Phone:</strong> <a href="tel:+919022694260">+91 9022694260</a></p>
      </div>
      
      <div className="social-media">
        <a href="https://www.facebook.com/techshamyani" className="social-link">Facebook</a> |
        <a href="https://www.twitter.com/techshamyani" className="social-link">Twitter</a> |
        <a href="https://www.linkedin.com/company/techshamyani" className="social-link">LinkedIn</a> |
        <a href="https://www.instagram.com/techshamyani" className="social-link">Instagram</a>
      </div>
      
      <p className="footer-bottom">&copy; 2024 TechShamyani. All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;
