import React, { useState, useEffect } from 'react';
import '../css/carousel.css'; // Adjust the path according to your project structure
import img1 from '../assets/images/image1.jpeg';
import img2 from '../assets/images/image2.jpeg';
import img3 from '../assets/images/image3.jpeg';
import img4 from '../assets/images/image4.jpeg';
import img5 from '../assets/images/image5.jpeg';

const images = [img1, img2, img3, img4, img5];

function Carousel() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(goToNext, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section id="carousel" className="carousel">
      <div className="carousel-wrapper">
        <div className="carousel-track" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          {images.map((img, index) => (
            <div key={index} className={`carousel-item ${currentIndex === index ? 'active' : ''}`}>
              <img src={img} alt={`Slide ${index}`} />
            </div>
          ))}
        </div>
      </div>
  
    </section>
  );
}

export default Carousel;
