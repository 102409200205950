import React from 'react';
import abhinav from '../assets/images/abhinav.jpeg';
import raushan from '../assets/images/raushan.jpeg';
import ratan from '../assets/images/ratan.jpeg';


import '../css/tst.css'; // Import the CSS for styling

const testimonials = [
  {
    name: 'Ratan Choudhary',
    feedback: 'The service was fantastic! The team was professional, and the results exceeded my expectations.',
    image: ratan,
    rating: 5
  },
  {
    name: 'Abhinav Kumar Jha',
    feedback: 'A wonderful experience from start to finish. Highly recommended!',
    image: abhinav,
    rating: 4
  },
  {
    name: 'Raushan Kumar',
    feedback: 'Exceptional quality and support. I am extremely satisfied with the outcome!',
    image: raushan,
    rating: 5
  }
];

const Testimonials = () => {
  return (
    <div className="testimonials">
      <h2>What Our Customers Say</h2>
      <div className="testimonial-cards">
        {testimonials.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <div className="testimonial-image">
              <img src={testimonial.image} alt={testimonial.name} />
              <div className="rating">
                {[...Array(5)].map((_, i) => (
                  <span key={i} className={i < testimonial.rating ? 'star filled' : 'star'}>&#9733;</span>
                ))}
              </div>
            </div>
            <div className="testimonial-content">
              <h3>{testimonial.name}</h3>
              <p>{testimonial.feedback}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
