import React, { useState, useEffect, useRef } from 'react';
import '../css/Hero.css';
import ParticlesBackground from './ParticlesBackground';
import logo from '../assets/images/logo.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Carousel from './stack';

function Hero() {
  const [navActive, setNavActive] = useState(false);
  const navRef = useRef(null);
  const toggleRef = useRef(null);

  const handleScroll = () => {
    const header = document.querySelector('.header');
    if (window.scrollY > 50) {
      header.classList.add('scrolled');
    } else {
      header.classList.remove('scrolled');
    }
  };

  const handleToggle = () => {
    setNavActive(prev => !prev);
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target) && !toggleRef.current.contains(event.target)) {
      setNavActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <section id="hero" className="hero">
      <ParticlesBackground />
      <header className="header">
        <div className="logo">
          <img src={logo} alt="Tech Shamyani Logo" />
          <span>Tech Shamyani</span>
        </div>
        <nav ref={navRef} className={navActive ? 'active' : ''}>
          <ul>
            <li><a href="#hero">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#portfolio">Portfolio</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
        <span 
          ref={toggleRef} 
          className="nav-toggle" 
          aria-label="Toggle navigation"
          onClick={handleToggle}
        >
          &#9776;
        </span>
      </header>
      <div className="hero-content">
        <h1>Building the Future of Digital Experiences</h1>
        <p>Innovative web and mobile solutions that propel your business forward.</p>
        <a href="https://wa.me/+919022694260" className="cta-button">
          <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon" />
          Whatsapp Us
        </a>
        <Carousel />

      </div>

    </section>
  );
}

export default Hero;
