import { useEffect, useState, useMemo } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

const ParticlesBackground = () => {
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        const initializeParticles = async () => {
            try {
                await initParticlesEngine(async (engine) => {
                    await loadSlim(engine);
                });
                setIsInitialized(true);
            } catch (error) {
                console.error("Error initializing particles engine:", error);
            }
        };

        initializeParticles();
    }, []);

    const particlesOptions = useMemo(() => ({
        background: {
            color: {
                value: "#101010",
            },
        },
        fpsLimit: 120,
        interactivity: {
            events: {
                onClick: {
                    enable: false,
                    mode: "push",
                },
                onHover: {
                    enable: true,
                    mode: "repulse",
                },
                resize: true,
            },
            modes: {
                push: {
                    quantity: 4,
                },
                repulse: {
                    distance: 100,
                    duration: 0.4,
                },
            },
        },
        particles: {
            color: {
                value: "#ffffff",
            },
            links: {
                color: "#ffffff",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
            },
            move: {
                direction: "none",
                enable: true,
                outModes: {
                    default: "bounce",
                },
                random: false,
                speed: 3,
                straight: false,
            },
            number: {
                density: {
                    enable: true,
                    area: 800,
                },
                value: 200,
            },
            opacity: {
                value: 0.5,
            },
            shape: {
                type: "circle",
            },
            size: {
                value: { min: 1, max: 5 },
            },
        },
        detectRetina: true,
    }), []);

    const particlesLoaded = (container) => {
        console.log("Particles loaded:", container);
    };

    return isInitialized ? (
        <Particles
            id="tsparticles"
            particlesLoaded={particlesLoaded}
            options={particlesOptions}

        />
    ) : null;
};

export default ParticlesBackground;
