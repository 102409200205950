import React from 'react';
import '../css/stack.css'; // Import the CSS file for styling and animations

// Import images from the assets folder
import icon1 from '../assets/images/android.png';
import icon2 from '../assets/images/apple.png';
import iconCenter from '../assets/images/www.png';
import icon4 from '../assets/images/windows.png';
import icon5 from '../assets/images/ubuntu.png';

const Stacks = () => {
  return (
    <div className="stacks-container">
      <div className="icon icon-1" style={{ backgroundImage: `url(${icon1})` }}></div>
      <div className="icon icon-2" style={{ backgroundImage: `url(${icon2})` }}></div>
      <div className="icon icon-center" style={{ backgroundImage: `url(${iconCenter})` }}></div>
      <div className="icon icon-4" style={{ backgroundImage: `url(${icon4})` }}></div>
      <div className="icon icon-5" style={{ backgroundImage: `url(${icon5})` }}></div>
    </div>
  );
};

export default Stacks;
