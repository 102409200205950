import React from 'react';
import '../css/About.css';

function About() {
  return (
    <section id="about" className="about">
      <h2>About Us</h2>
      <p>At Tech Shamyani, we are dedicated to transforming visionary ideas into exceptional digital solutions. Based in Begusarai, Bihar, our team of seasoned professionals excels in mobile and web app development, as well as UI/UX design. We specialize in creating custom applications for Android and iOS platforms, Windows apps, and more, all while adhering to the highest industry standards.

Our approach combines creativity with cutting-edge technology to deliver tailored solutions that meet the unique needs of our clients. At Tech Shamyani, we are committed to pushing the boundaries of digital innovation, ensuring that your vision is realized with precision and efficiency. By focusing on delivering high-quality, user-centric applications, we strive to exceed expectations and create meaningful, lasting impacts for our customers.</p>
    </section>
  );
}

export default About;
