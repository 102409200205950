import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../css/Contact.css'; // Import the CSS for styling

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [loading, setLoading] = useState(false); // Added loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submission starts

    emailjs.sendForm('service_xzaxkir', 'template_7hlgijw', e.target, 'gNgebWy9om64U9vzh')
      .then((result) => {
        alert('Message sent successfully!');
        setFormData({ name: '', email: '', message: '' }); // Clear the form
        setLoading(false); // Set loading to false when submission is complete
      }, (error) => {
        alert('Failed to send message. Please try again later.');
        console.error(error.text);
        setLoading(false); // Set loading to false if an error occurs
      });
  };

  return (
    <div id="contact" className="contact">
      <h2>Contact Us</h2>
      <div className="contact-details">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name <span className="required">*</span></label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your Name"
              required
              value={formData.name}
              onChange={handleChange}
              aria-label="Your Name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email <span className="required">*</span></label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your Email"
              required
              value={formData.email}
              onChange={handleChange}
              aria-label="Your Email"
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message <span className="required">*</span></label>
            <textarea
              id="message"
              name="message"
              placeholder="Your Message"
              required
              value={formData.message}
              onChange={handleChange}
              aria-label="Your Message"
            ></textarea>
          </div>

          <div className="button-container">
            <button type="submit" disabled={loading}>
              {loading ? <span className="loader"></span> : 'Send Message'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
