import React from 'react';
import '../css/Services.css';
import webDevImage from '../assets/images/web-dev.jpeg'; // Path to your image
import mobileAppImage from '../assets/images/mobile-app.jpeg'
import uiUxImage from '../assets/images/ui-ux.jpeg'; // Path to your image

function Services() {
  return (
    <section id="services" className="services">
      <h2>Our Services</h2>
      <div className="service-cards">
        <div className="service-card">
          <img src={webDevImage} alt="Web Development" className="service-image" />
          <h3>Web Development</h3>
          <p>From sleek, responsive websites to robust e-commerce platforms, we build it all.</p>
        </div>
        <div className="service-card">
          <img src={mobileAppImage} alt="Mobile App Development" className="service-image" />
          <h3>Mobile App Development</h3>
          <p>Create mobile experiences that captivate and engage users on iOS and Android.</p>
        </div>
        <div className="service-card">
          <img src={uiUxImage} alt="UI/UX Design" className="service-image" />
          <h3>UI/UX Design</h3>
          <p>Beautiful, user-friendly designs that enhance your brand and engage your audience.</p>
        </div>
      </div>
    </section>
  );
}

export default Services;
